import Layout from 'components/layout';
import ProductLayout from 'components/layouts/product';
import { ProductsContainer } from 'components/product';
import Container from 'components/ui/container';
import { graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React, { ReactChild } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useIntl } from 'react-intl';

export default function ProductsSingleTemplate({
    data,
}: {
    data: GatsbyTypes.ProductItemQuery;
}) {
    const carouselItems = data.mdx?.frontmatter?.images?.map((image) => (
        <GatsbyImage
            key={image?.childImageSharp?.id as string}
            image={
                (image?.childImageSharp
                    ?.gatsbyImageData as unknown) as IGatsbyImageData
            }
            alt="Product Image"
        />
    ));

    const relatedProducts = data.relatedProducts.nodes.map((node) => ({
        id: node.id as string,
        title: node.frontmatter?.title as string,
        category: node.frontmatter?.category as string,
        slug: node.fields?.slug as string,
        image: (node.frontmatter?.image?.childImageSharp
            ?.gatsbyImageData as unknown) as IGatsbyImageData,
    }));
    const intl = useIntl();

    return (
        <Layout
            seo={{
                title: data.mdx?.frontmatter?.title as string,
                description: data.mdx?.frontmatter?.description as string,
                image: data.mdx?.frontmatter?.image?.publicURL as string,
            }}
            showNavbarPlaceholder={true}
        >
            <div className="py-1 md:py-7 lg:py-14 products">
                <GoogleReCaptchaProvider
                    reCaptchaKey="6LfrErIaAAAAAOTmYAD5lk_wvOnqKkOBXuB7F_p5"
                    language={intl.locale}
                >
                    <Container>
                        <div className="flex flex-wrap page">
                            <ProductLayout
                                carouselItems={carouselItems as ReactChild[]}
                                productData={{
                                    title: data.mdx?.frontmatter
                                        ?.title as string,
                                    category: data.mdx?.frontmatter
                                        ?.category as string,
                                    description: data.mdx?.frontmatter
                                        ?.description as string,
                                    product: data.mdx?.frontmatter
                                        ?.product as string,
                                    slug: data.mdx?.fields?.slug as string,
                                }}
                            >
                                <MDXRenderer>
                                    {data.mdx?.body as string}
                                </MDXRenderer>
                            </ProductLayout>
                        </div>
                        {relatedProducts.length > 0 && (
                            <div className="mt-6 px-3 lg:px-0">
                                <h2 className="text-3xl font-black text-gray-900 text-center mb-6">
                                    {intl.formatMessage({
                                        id: 'relatedProducts',
                                    })}
                                </h2>
                                <ProductsContainer products={relatedProducts} />
                            </div>
                        )}
                    </Container>
                </GoogleReCaptchaProvider>
            </div>
        </Layout>
    );
}

export const query = graphql`
    query ProductItem($locale: String!, $slug: String!, $category: String!) {
        relatedProducts: allMdx(
            filter: {
                fields: {
                    locale: { eq: "en" }
                    sourceName: { eq: "products" }
                    slug: { ne: $slug }
                }
                frontmatter: { category: { eq: $category } }
            }
        ) {
            nodes {
                id
                frontmatter {
                    title
                    category
                    description
                    product
                    image {
                        childImageSharp {
                            id
                            gatsbyImageData
                        }
                    }
                }
                fields {
                    slug
                }
            }
        }
        mdx(fields: { slug: { eq: $slug }, locale: { eq: $locale } }) {
            frontmatter {
                title
                category
                description
                product
                image {
                    publicURL
                }
                images {
                    childImageSharp {
                        id
                        gatsbyImageData
                    }
                }
            }
            fields {
                slug
            }
            body
        }
    }
`;

import React, { ReactNode, ReactPortal, useState } from 'react';

export default function Tabbed({ children }: { children: ReactNode }) {
    const [active, setActive] = useState(0);

    const controls: ReactNode[] = [];

    React.Children.forEach(children, (_item, index) => {
        const item = _item as ReactPortal;
        controls.push(
            <TabbedControl
                active={active === index}
                index={index}
                setActive={setActive}
                title={item.props.title}
                key={item.props.title + index}
            />
        );
    });

    return (
        <div>
            <div className="flex items-center flex-wrap">{controls}</div>
            <div className="w-full py-4">
                {React.Children.toArray(children)[active]}
            </div>
        </div>
    );
}

export const TabbedItem: React.FC<{ title: string }> = ({
    title,
    children,
}) => {
    return <div>{children}</div>;
};

const TabbedControl = ({
    index,
    title,
    setActive,
    active,
}: {
    index: number;
    title: string;
    setActive: (index: number) => void;
    active: boolean;
}) => {
    return (
        <button
            className={
                (active ? `bg-gray-600 text-white focus:ring-0 focus:outline-none` : `bg-gray-200 text-black`) +
                ' px-5 py-3'
            }
            onClick={() => {
                if (!active) {
                    setActive(index);
                }
            }}
        >
            {title}
        </button>
    );
};

import React, { ReactChild, ReactElement, ReactPortal, useState } from 'react';
import { MDXProvider } from '@mdx-js/react';
import { ReactNode } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Tabbed, { TabbedItem } from 'components/ui/tabs';
import { useIntl } from 'react-intl';
import QuoteForm from 'components/quoteform';

interface ProductData {
    title: string;
    category: string;
    description: string;
    product: string;
    slug: string;
}

const components = {
    Info,
    Specifications,
    Environmental,
    Downloads,
    Video,
    wrapper: ({
        children,
        carousel,
        productData,
    }: {
        children: ReactNode;
        carousel: ReactChild[];
        productData: ProductData;
    }) => {
        const intl = useIntl();

        const data: Record<
            'info' | 'specifications' | 'environmental' | 'downloads' | 'video',
            ReactNode
        > = {
            info: null,
            downloads: null,
            video: null,
            specifications: null,
            environmental: null,
        };

        React.Children.forEach(children, (_child) => {
            const child = _child as ReactPortal;

            if (child.props.mdxType === 'Info') {
                data.info = child;
                return;
            }

            if (child.props.mdxType === 'Specifications') {
                data.specifications = child;
                return;
            }

            if (child.props.mdxType === 'Environmental') {
                data.environmental = child;
                return;
            }

            if (child.props.mdxType === 'Downloads') {
                data.downloads = child;
                return;
            }

            if (child.props.mdxType === 'Video') {
                data.video = child;
                return;
            }
        });

        return (
            <React.Fragment>
                <div className="w-full lg:w-2/5 shadow-xl">
                    <Carousel
                        autoPlay={true}
                        showArrows={true}
                        swipeable={true}
                        interval={3000}
                        showThumbs={false}
                    >
                        {carousel}
                    </Carousel>
                </div>
                <div className="w-full lg:w-3/5 px-3 pl-0 lg:pl-10">
                    <div className="mb-4">
                        <h1 className="main-title text-xl text-gray-900 font-black mt-8 lg:mt-0">
                            {productData.title}
                        </h1>
                        <p className="text-gray-700">{productData.product}</p>
                    </div>
                    {data.info}
                </div>
                <div className="w-full my-6">
                    <Tabbed>
                        <TabbedItem
                            title={intl.formatMessage({
                                id: 'technicalSpecifications',
                            })}
                        >
                            {data.specifications}
                        </TabbedItem>
                        <TabbedItem
                            title={intl.formatMessage({
                                id: 'environmental',
                            })}
                        >
                            {data.environmental}
                        </TabbedItem>
                        <TabbedItem
                            title={intl.formatMessage({
                                id: 'downloads',
                            })}
                        >
                            {data.downloads || (
                                <p>
                                    {intl.formatMessage({
                                        id: 'noDownloads',
                                    })}
                                </p>
                            )}
                        </TabbedItem>
                        <TabbedItem
                            title={intl.formatMessage({
                                id: 'video',
                            })}
                        >
                            {data.video || (
                                <p>
                                    {intl.formatMessage({
                                        id: 'noVideo',
                                    })}
                                </p>
                            )}
                        </TabbedItem>
                        <TabbedItem
                            title={intl.formatMessage({
                                id: 'getQuote',
                            })}
                        >
                            <QuoteForm
                                product={{
                                    ...productData,
                                    slug: `https://amdtec.com.tr/${productData.slug}`,
                                }}
                            />
                        </TabbedItem>
                    </Tabbed>
                </div>
            </React.Fragment>
        );
    },
};

function Info({ children }: { children: ReactNode }) {
    return <div className="info">{children}</div>;
}

function Specifications({ children }: { children: ReactNode }) {
    return <div className="specifications">{children}</div>;
}

function Environmental({ children }: { children: ReactNode }) {
    return <div className="environmental">{children}</div>;
}

function Downloads({ children }: { children: ReactNode }) {
    return <div className="downloads">{children}</div>;
}

function Video({ children }: { children: ReactNode }) {
    return <div className="video w-full md:w-5/6">{children}</div>;
}

export default function ProductLayout({
    children,
    carouselItems,
    productData,
}: {
    children: ReactNode;
    carouselItems: ReactChild[];
    productData: ProductData;
}) {
    return (
        <MDXProvider components={components}>
            {React.cloneElement(children as ReactElement, {
                carousel: carouselItems,
                productData,
            })}
        </MDXProvider>
    );
}
